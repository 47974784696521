"use client"
import "@/styles/globals.css";
import "@/styles/brickswall.less"
import Head from "next/head";
import Link from "next/link";
import Script from "next/script";
import { useEffect } from "react";
import { useRouter } from "next/router";
// import * as ga from "@/libs/analytics";


export default function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    // if (!window.GA_INITIALIZED) {
    //   initializeGA();
    //   window.GA_INITIALIZED = true;
    // }
    const head = document.head;
    const script = document.createElement("script");
    script.innerHTML = `
      setTimeout(function(){var a=document.createElement("script");
      var b=document.getElementsByTagName("script")[0];
      a.src=document.location.protocol+"//script.crazyegg.com/pages/scripts/0055/6070.js?"+Math.floor(new Date().getTime()/3600000);
      a.async=true;a.type="text/javascript";b.parentNode.insertBefore(a,b)}, 1);
    `;
    head.appendChild(script);
    const handleRouteChange = (url) => {
      // ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  return <>
    <Head>
      <link rel="icon" href="/favicon.png" />
      <title>Ledgerowl</title>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
      <link rel="icon" href="/favicon.png" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content="Ledgerowl" />
      <meta property="og:url" content="https://ledgerowl.com/" />
      <meta property="og:image" content="https://dev-ledgerowl.web.app/image-og.jpg" />
      <meta property="og:type" content="article" />
      <meta property="og:description" content="From bookkeeping to tax, Ledgerowl is the all-in-one AI powered financial solution that your business can trust." />
    </Head>
    <Component {...pageProps} />
  </>
}
